//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select';
import { post } from '@rails/request.js';

export default class extends Controller
{
  static values = { addable: Boolean, apiUrl: String };

  connect()
  {
    let options =
    {
      select: this.element,
      events: {}
    }

    if( this.addableValue )
    {
      options.events.addable = this.add.bind( this );
    }

    this.ss = new SlimSelect( options );
  }

  disconnect()
  {
    this.ss.destroy();
    this.ss = null;
  }

  focus( e )
  {
    this.ss.open();
  }

  async add( value )
  {
    if( this.hasApiUrlValue )
    {
      const data = { value: value };
      const response = await post( this.apiUrl, { body: JSON.stringify( data ) } );

      if( response.ok )
      {
        const result = await response.json
        return result;
      }
      else
      {
        return false;
      }
    }
    else
    {
      return value;
    }
  }

  get apiUrl()
  {
    if( this.hasApiUrlValue )
    {
      return this.apiUrlValue;
    }
  }
}